import React from "react"

import * as styles from "./Loading.module.scss"

const Loading = (props) => (
    <div className={styles.Loading}>
        {/* <button onClick={props.onClick} disabled={props.disabled} data-loading={props.loading}>
            Load more {props.loading}
        </button> */}
        {props.loading && (
            <div className={styles.spinner}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        )}
        {/*<a onClick={props.onClick} href={props.href}>Load more</a>*/}
    </div>
)

export default Loading
