import React, { useEffect, useMemo, useCallback, useState } from "react"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

// Components
import LeaderList from "../../components/Shared/LeaderList"
import HeroAreaSmall from "../../components/Shared/HeroAreaSmall"
import Main from "../../components/Shared/Main"
import Leader from "../../components/Shared/Leader"
import LoadMore from "../Shared/LoadMore"
import Loading from "../Shared/Loading"

import * as styles from "./NewsContainer.module.scss"

export const query = gql`
    query NewsQuery($first: Int!, $after: String, $notIn: [ID!]) {
        news: allNews(first: $first, after: $after, where: { notIn: $notIn }) {
            results: nodes {
                id
                databaseId
                title
                publishToLive {
                    publishToLive
                }
                link
                excerpt
                contentType {
                    node {
                        name
                    }
                }
                image: hero_fields {
                    image {
                        sourceUrl
                        title
                    }
                }
            }
            pageInfo {
                hasNextPage
            }
        }
    }
`

const PER_PAGE = 18
const PAGINATION_OFFSET = 0 // initial page load gets 8 but we then render addition rows of 9

// Window Object
let store = typeof window === "undefined" ? {} : window

const NewsContainer = (props) => {
    // Whats On Context

    // console.log(props)

    const [params, setParams] = useState({ first: PER_PAGE, after: null })

    let { data, loading, fetchMore } = useQuery(query, {
        variables: params,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        errorPolicy: "all",
    })

    // console.log('PARAMS', params)
    // console.log('DATA', data)

    // const updateFilteredResults = useCallback(
    //     (filters) => {
    //         const updatedParams = { ...params, ...filters }
    //         setParams(updatedParams)
    //     },
    //     [params]
    // )

    const allNews = useMemo(() => {
        let news

        // console.log(data)

        if (data) {
            news = data?.news ? data?.news?.results : []
        } else {
            news = props.data.allNews.results
        }

        const filteredNews = news.filter((item) => {
            // LIVE - PREVIEW
            if (process.env.GATSBY_IS_LIVE_OR_PREVIEW !== "live") {
                return true
            } else {
                // If the event has a publishToLive field, check if it is set to "Live"
                // If it is not set, return true as this should be the default
                if (item?.publishToLive?.publishToLive) {
                    return item?.publishToLive?.publishToLive === "Live"
                } else {
                    return true
                }
            }
        })

        return [...filteredNews]
    })

    return (
        <>
            <Main>
                {allNews.length === 0 && <p>No news found</p>}

                {!loading ? (
                    <>
                        <LeaderList layoutType="oneColumn">
                            {allNews.slice(0, 1).map((news) => (
                                <Leader key={news.id} {...news} link={{ url: news.link }} image={news.image.image} />
                            ))}
                        </LeaderList>

                        <LeaderList layoutType="twoColumn">
                            {allNews.slice(1, 3).map((news) => (
                                <Leader key={news.id} {...news} link={{ url: news.link }} image={news.image.image} />
                            ))}
                        </LeaderList>

                        <LeaderList layoutType="threeColumn">
                            {allNews.slice(3).map((news) => (
                                <Leader key={news.id} {...news} link={{ url: news.link }} image={news.image.image} />
                            ))}
                        </LeaderList>
                    </>
                ) : (
                    <div>
                        <Loading loading={true} />
                    </div>
                )}

                {data && (
                    <LoadMore
                        onClick={() => {
                            fetchMore({
                                variables: {
                                    first: params.first + PAGINATION_OFFSET,
                                    after: null,
                                    notIn: allNews.map((e) => e.databaseId),
                                },
                                updateQuery: (prev, { fetchMoreResult }) => {
                                    if (!fetchMoreResult) {
                                        return prev
                                    }

                                    let results = [...prev.news.results, ...fetchMoreResult.news.results]
                                    return {
                                        ...prev,
                                        ...{ news: { results, pageInfo: fetchMoreResult.news.pageInfo } },
                                    }
                                },
                            })
                        }}
                        disabled={data && data.news && !data.news.pageInfo.hasNextPage}
                        loading={loading}
                    />
                )}
            </Main>
        </>
    )
}

export default NewsContainer
