import gql from "graphql-tag"

export const getNewsPagePreview = gql`
    query getNewsPagePreview($id: ID!, $revision_id: Int!) {
        page(id: $id, idType: DATABASE_ID) {
            title
            revisions(where: { id: $revision_id }) {
                edges {
                    node {
                        id
                        title
                        link
                        content
                    }
                }
            }
        }
    }
`

export const getNewsPreview = gql`
    query getNewsPreview($id: Int!, $revision_id: Int!) {
        news: newsBy(newsId: $id) {
            title
            revisions(where: { id: $revision_id }) {
                edges {
                    node {
                        id
                        title
                        content
                        date
                        link
                        excerpt
                        image: hero_fields {
                            image {
                                sourceUrl
                                title
                            }
                        }
                        author {
                            firstName
                            lastName
                        }
                        news_fields {
                            articleDate
                            author {
                                ... on Person {
                                    title(format: RENDERED)
                                    personId
                                }
                            }
                        }
                        sidebar_calls {
                            sidebarCtas {
                                ... on CTA {
                                    id
                                    title
                                    cta_fields {
                                        link {
                                            title
                                            target
                                            url
                                        }
                                        description
                                        image {
                                            sourceUrl
                                            title
                                        }
                                    }
                                }
                            }
                        }
                        person_ctas {
                            people {
                                ... on Person {
                                    id
                                    title
                                    person_fields {
                                        ctaDescription
                                        email: emailAddress
                                        role
                                        phone: telephoneNumber
                                        image {
                                            sourceUrl
                                            title
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
