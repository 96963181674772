import React from "react"
import { navigate } from "gatsby"
import withPreview, { getContent } from "../apollo/preview"
import { getNewsPagePreview as PREVIEW_QUERY } from "../preview-queries/newsPreview"

// Data
import { graphql } from "gatsby"

// Components
import Layout from "../components/Global/Layout"
import Main from "../components/Shared/Main"
import LoadMore from "../components/Shared/LoadMore"
import Leader from "../components/Shared/Leader"
import LeaderList from "../components/Shared/LeaderList"
import HeroAreaSmall from "../components/Shared/HeroAreaSmall"
import NewsContainer from "../components/News/NewsContainer"

import { venueForPage } from "../helpers/venueForPage"
import SEOSettings from "../components/Shared/SEOSettings"

const NewsPage = (props) => {
    const page = getContent(props, "page")
    // const news = props.data.news.nodes.map((newsItem) => {
    //     const { image, ...item } = newsItem
    //     item.image = image ? image.image : null
    //     return item
    // })

    const venueChildPage = page.ancestors?.nodes && page.ancestors.nodes.slice(-1)[0].pageTemplate === "home-page"
    const ancestorsCount = page.ancestors?.nodes && page.ancestors.nodes.length

    let superTitle
    if (venueChildPage && ancestorsCount === 2) {
        superTitle = page.ancestors.nodes.slice(-2)[0].title
    } else if (!venueChildPage && page.ancestors?.nodes) {
        superTitle = page.ancestors.nodes.slice(-1)[0].title
    }

    // const leadItem = (
    //     <LeaderList layoutType="oneColumn" styleType="news">
    //         {news.slice(0, 1).map((leader) => (
    //             <Leader key={leader.id} {...leader} link={{ url: leader.link }} />
    //         ))}
    //     </LeaderList>
    // )

    // const secondaryItems = (
    //     <LeaderList layoutType="twoColumn" styleType="news">
    //         {news.slice(1, 3).map((leader) => (
    //             <Leader key={leader.id} {...leader} link={{ url: leader.link }} />
    //         ))}
    //     </LeaderList>
    // )

    // const otherItems = (
    //     <LeaderList layoutType="threeColumn" styleType="news">
    //         {news.slice(3).map((leader) => (
    //             <Leader key={leader.id} {...leader} link={{ url: leader.link }} />
    //         ))}
    //     </LeaderList>
    // )

    return (
        <Layout venue={venueForPage({ page: page, menus: props.data.menus })} currentLocation={props.path} title={page.title}>
            <SEOSettings entity={page} />
            <HeroAreaSmall title={page.title} image={page.image.image}></HeroAreaSmall>

            <NewsContainer {...props} />
        </Layout>
    )
}

export const pageQuery = graphql`
    query NewsIndexPageQuery($databaseId: Int!, $newsArticleIds: [Int]) {
        menus: allWpMenu {
            results: nodes {
                ...PageMenusNode
            }
        }

        page: wpPage(databaseId: { eq: $databaseId }) {
            ...PageContent
            seo {
                metaDesc
                title
                opengraphTitle
                opengraphDescription
                metaKeywords
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphImage {
                    sourceUrl
                }
            }
        }
        allNews: allWpNews(filter: { databaseId: { in: $newsArticleIds } }) {
            results: nodes {
                ...BasicNewsContent
            }
        }
    }
`

export default withPreview({ preview: PREVIEW_QUERY })(NewsPage)
