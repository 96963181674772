import React from "react"

import * as styles from "./LoadMore.module.scss"

const LoadMore = (props) => (
    <div className={styles.LoadMore}>
        <button onClick={props.onClick} disabled={props.disabled} data-loading={props.loading}>
            Load more {props.loading}
        </button>
        {props.loading && (
            <div className={styles.spinner}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        )}
        {/*<a onClick={props.onClick} href={props.href}>Load more</a>*/}
    </div>
)

export default LoadMore
